<button class="lang" #anchor (click)="toggle()">
    {{ currentLang | uppercase }}
    <mat-icon class="arrow" svgIcon="icon_arrow"></mat-icon>
</button>
<button data-cy="header-actions-account_button" #anchorAccount (click)="toggleAccount()">
    <mat-icon svgIcon="icon_account" class="account"></mat-icon>
</button>
<button (click)="onHelp()">
    <mat-icon svgIcon="icon_help" class="help"></mat-icon>
</button>
<kendo-popup
    #popupAccount
    [anchor]="anchorAccount"
    [anchorAlign]="anchorAlign"
    [popupAlign]="popupAlign"
    popupClass="content"
    *ngIf="showAccount"
>
    <app-account-menu></app-account-menu>
</kendo-popup>

<kendo-popup #popup [anchor]="anchor" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign" popupClass="content" *ngIf="show">
    <app-lang></app-lang>
</kendo-popup>
