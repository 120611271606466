/* src/app/layout/components/account-menu/account-menu.component.scss */
button {
  background: none;
  border: none;
  min-width: 30px;
  cursor: pointer;
}
button:hover {
  font-weight: 500;
}
.language-item {
  margin-right: 4px;
  text-decoration: none;
  font-size: 1em;
}
.language-active {
  color: #4e1289;
  font-weight: 500;
}
/*# sourceMappingURL=account-menu.component.css.map */
