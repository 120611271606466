/* src/app/layout/components/header-actions/header-actions.component.scss */
:host {
  display: flex;
  flex-direction: row-reverse;
  margin: 0 20px;
}
:host ::ng-deep .k-popup {
  padding: 10px;
  background-color: #f8f8f8;
  overflow: hidden !important;
}
button {
  height: 23px;
  min-width: 23px;
  padding: 0 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: none;
  cursor: pointer;
  color: #4e1289;
  margin-left: 7px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  transition: all 0.25s ease;
}
button:hover {
  background: #f2f2f2;
}
button mat-icon {
  width: 18px;
  height: 18px;
}
button mat-icon.arrow {
  width: 11px;
  margin-left: 4px;
}
.lang {
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  font-size: 13px;
  font-weight: unset;
}
.arrow {
  width: 10px;
  vertical-align: middle;
}
.row {
  margin-bottom: 10px;
}
.account {
  max-height: 20px;
}
.help {
  max-height: 20px;
}
/*# sourceMappingURL=header-actions.component.css.map */
