import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LangComponent } from '@cue/admin-shared';
import { Align, PopupModule } from '@progress/kendo-angular-popup';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from '@cue/admin-constants';
import { HelpService } from '@cue/admin-core';
import { Router } from '@angular/router';
import { AccountMenuComponent } from '../account-menu/account-menu.component';

@Component({
  selector: 'app-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatIconModule, PopupModule, AccountMenuComponent, LangComponent],
})
export class HeaderActionsComponent implements OnInit {
  public CONSTANTS = CONSTANTS;
  public currentLang;

  public anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  public popupAlign: Align = { horizontal: 'right', vertical: 'top' };

  public toggleText = 'Show';
  public show = false;
  public showAccount = false;

  constructor(
    public translate: TranslateService,
    private helpService: HelpService,
    private router: Router,
  ) {
    this.currentLang = localStorage.getItem('currentLanguage');
  }

  @ViewChild('anchor', { read: ElementRef }) public anchor: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef;

  @ViewChild('anchorAccount', { read: ElementRef }) public anchorAccount: ElementRef;
  @ViewChild('popupAccount', { read: ElementRef }) public popupAccount: ElementRef;

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27) {
      this.toggle(false);
      this.toggleAccount(false);
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target)) {
      this.toggle(false);
      this.toggleAccount(false);
    }
  }

  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
    this.toggleText = this.show ? 'Hide' : 'Show';
    if (this.show == true) {
      this.showAccount = false;
    }
  }

  public toggleAccount(showAccount?: boolean): void {
    this.showAccount = showAccount !== undefined ? showAccount : !this.showAccount;
    this.toggleText = this.show ? 'Hide' : 'Show';
    if (this.showAccount == true) {
      this.show = false;
    }
  }

  private contains(target: any): boolean {
    return (
      this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false) ||
      this.anchorAccount.nativeElement.contains(target) ||
      (this.popupAccount ? this.popupAccount.nativeElement.contains(target) : false)
    );
  }

  public ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
  }

  public onHelp() {
    this.helpService.openHelp(CONSTANTS.help.confluenceOnline);
    /*let route = this.router.url.substring(1);
    var re = /\/\d+$/; // lomítko a čísla na konci řetězce
    this.helpService.openHelp(
      CONSTANTS.help.topicUrl + route.replace(re, '')
    );*/
  }
}
