/* src/app/layout/components/layout/layout.component.scss */
.header {
  background-color: #ffffff;
  height: 55px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 100;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Basic Sans", sans-serif;
  font-weight: 700;
  font-size: 1.3125rem;
}
@media only screen and (max-width: 768px) {
  .logo {
    font-size: 17px;
  }
}
.hamburger {
  width: 20px;
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
}
.hamburger .line {
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background: #000000;
  transition: all 0.25s ease;
}
.hamburger .line:not(:nth-last-of-type(1)) {
  margin-bottom: 3px;
}
.hamburger .line:nth-of-type(1) {
  width: 80%;
}
.hamburger .line:nth-of-type(2) {
  width: 100%;
}
.hamburger .line:nth-of-type(3) {
  width: 60%;
}
.hamburger:hover .line {
  background: #4e1289;
}
.hamburger:hover .line:nth-of-type(1) {
  width: 60%;
}
.hamburger:hover .line:nth-of-type(2) {
  width: 40%;
}
.hamburger:hover .line:nth-of-type(3) {
  width: 100%;
}
.logoIn {
  display: block;
  padding: 8px 10px;
  margin-right: 10px;
}
@media only screen and (max-width: 768px) {
  .logoIn {
    margin-right: 0;
  }
}
.logoSvg {
  display: block;
  vertical-align: middle;
  height: 2.625rem;
  pointer-events: none;
}
#layout {
  display: flex;
  overflow: auto;
  position: absolute;
  top: 55px;
  bottom: 0;
  height: calc(100% - 55px);
  width: 100%;
  background: #e5e5e5;
}
#layout.menu-collapsed #layoutLeft {
  left: -100%;
  position: absolute;
}
#layout.menu-collapsed #layoutRight {
  width: calc(100% - 24px * 2);
}
@media only screen and (max-width: 768px) {
  #layout:not(.menu-collapsed) #layoutRight::before {
    opacity: 1;
    z-index: 14;
  }
}
#layout.header-hidden {
  top: -1px;
  z-index: 200;
}
#layoutLeft {
  width: 250px;
  height: 100%;
  overflow-y: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  position: absolute;
  z-index: 15;
  transition: all 0.25s ease;
  flex-shrink: 0;
  left: 0;
}
#layoutRight {
  position: absolute;
  right: 0;
  width: calc(100% - 250px - 24px * 2);
  height: calc(100% - 20px * 2);
  padding: 20px 24px;
  transition: all 0.25s ease;
  overflow: hidden;
}
@media only screen and (max-width: 992px) {
  #layoutRight {
    padding: 15px 10px;
    width: calc(100% - 10px * 2) !important;
  }
}
@media only screen and (max-width: 768px) {
  #layoutRight::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: opacity 0.25s ease;
    z-index: -1;
  }
}
/*# sourceMappingURL=layout.component.css.map */
