<div class="header">
    <div class="logo">
        <div class="hamburger" (click)="menuCollapsing()">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
        <a href="/index.html" class="logoIn">
            <object data="assets/img/logo.svg" type="image/svg+xml" class="logoSvg"></object>
        </a>
        CUE Admin
    </div>
    <div class="header-actions">
        <app-header-actions></app-header-actions>
    </div>
</div>
<div id="layout" [class.menu-collapsed]="menuCollapsed$ | async" [class.header-hidden]="headerHidden$ | async">
    <div id="layoutLeft">
        <app-menu></app-menu>
    </div>
    <div id="layoutRight" (click)="closeMobileMenuWithOverlay()">
        <router-outlet></router-outlet>
    </div>
</div>
