import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AccountService } from '@cue/admin-account';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
  standalone: true,
})
export class AccountMenuComponent {
  constructor(
    private accountService: AccountService,
    private router: Router,
  ) {}

  onSetup() {
    this.router.navigateByUrl('/setup');
  }

  onLogout() {
    this.accountService.actionLogout().subscribe((_) => {
      this.router.navigateByUrl('/account/login');
    });
  }
}
