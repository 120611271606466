import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { PanelBarModule } from '@progress/kendo-angular-layout';
import { Copyright, MenuItemModel2 } from '@cue/admin-shared';
import { CONSTANTS } from '@cue/admin-constants';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccountService } from '@cue/admin-account';
import { AnalyticsDirective, ConfigService, WipDirective } from '@cue/admin-core';

import { filter } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AnalyticsDirective,
    PanelBarModule,
    TranslateModule,
    WipDirective,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    Copyright,
  ],
})
export class MenuComponent {
  public userRoleId: number;
  public title: string;

  public menu: MenuItemModel2[] = [
    {
      title: 'menu.home',
      roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole],
      icon: 'icon_home',
      items: [
        {
          title: 'menu.dashboard',
          path: CONSTANTS.paths.dashboard,
          icon: 'icon_menu_setup',
          roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole],
        },
      ],
    },
    {
      title: 'menu.resources',
      icon: 'icon_resources',
      items: [
        {
          title: 'menu.list',
          path: CONSTANTS.paths.resources,
          icon: 'icon_menu_resources',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.resourceTypes',
          path: CONSTANTS.paths.resourceTypes,
          icon: 'icon_menu_properties',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.resourceProperties',
          path: CONSTANTS.paths.resourceProperties,
          icon: 'icon_menu_properties',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.imageTemplates',
          path: CONSTANTS.paths.imageTemplates,
          roles: [CONSTANTS.code.adminRole],
        },
      ],
      roles: [CONSTANTS.code.adminRole],
    },
    {
      title: 'menu.units',
      icon: 'icon_units',
      roles: [CONSTANTS.code.adminRole],
      items: [
        {
          title: 'menu.list',
          path: CONSTANTS.paths.units,
          icon: 'icon_menu_units',
        },
        {
          title: 'menu.projects',
          path: CONSTANTS.paths.projects,
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.settings',
          path: CONSTANTS.paths.properties,
          icon: 'icon_menu_properties',
        },
        {
          title: 'menu.sensor',
          path: CONSTANTS.paths.sensor,
          icon: 'icon_menu_sensor',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.timeSettings',
          path: CONSTANTS.paths.timeSettings,
          icon: 'icon_menu_time_settings',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.timetables',
          path: CONSTANTS.paths.timetables,
          icon: 'icon_menu_timetables',
          roles: [CONSTANTS.code.adminRole],
        },
      ],
    },
    {
      title: 'menu.userManagement',
      icon: 'icon_user',
      items: [
        {
          title: 'menu.users',
          path: CONSTANTS.paths.users,
          icon: 'icon_menu_users',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.groups',
          path: CONSTANTS.paths.userGroups,
          icon: 'icon_menu_users',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.adminUsers',
          path: CONSTANTS.paths.adminUsers,
          icon: 'icon_menu_setup',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.profile',
          path: CONSTANTS.paths.setup,
          icon: 'icon_menu_setup',
          roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole],
        },
      ],
      roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole],
    },
    {
      title: 'menu.touchONEAssist',
      icon: 'icon_assist',
      items: [
        {
          path: CONSTANTS.paths.touchONEAssist,
          icon: 'icon_menu_properties',
          title: 'menu.settings',
        },
      ],
      roles: [CONSTANTS.code.adminRole],
    },
    {
      title: 'menu.smartBuilding',
      icon: 'icon_smart_building',
      items: [
        {
          title: 'menu.areas',
          path: CONSTANTS.paths.areas,
          icon: 'icon_menu_areas',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.serviceManagement',
          path: CONSTANTS.paths.support,
          icon: 'icon_menu_support',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.navigation',
          path: CONSTANTS.paths.navigation,
          icon: 'icon_menu_areas',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.qrCodes',
          path: CONSTANTS.paths.qrCodes,
          icon: 'icon_menu_properties',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.qrCodeCategories',
          path: CONSTANTS.paths.qrCategories,
          icon: 'icon_menu_properties',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.paths',
          path: CONSTANTS.paths.paths,
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.kiosks',
          path: CONSTANTS.paths.kiosks,
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.digitalSignage',
          path: CONSTANTS.paths.digitalSignage,
          icon: 'icon_menu_analytics_template',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.news',
          path: CONSTANTS.paths.news,
          icon: 'icon_menu_analytics_template',
          roles: [CONSTANTS.code.adminRole],
        },
      ],
      roles: [CONSTANTS.code.adminRole],
    },
    {
      title: 'menu.connections',
      icon: 'icon_menu_connection_agents',
      items: [
        {
          title: 'menu.connectionAgents',
          path: CONSTANTS.paths.connectionAgents,
          icon: 'icon_menu_connection_agents',
        },
        {
          title: 'menu.webex',
          path: CONSTANTS.paths.webex,
          icon: 'icon_menu_analytics_basic_overview',
        },
        {
          title: 'menu.googleAnalytics',
          path: CONSTANTS.paths.googleAnalytics,
          icon: 'icon_menu_analytics_basic_overview',
        },
      ],
      roles: [CONSTANTS.code.adminRole],
    },
    {
      title: 'menu.analytics',
      path: CONSTANTS.paths.analytics,
      icon: 'icon_analytics',
      roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole],
      items: [
        {
          title: 'menu.newAnalytics',
          path: CONSTANTS.paths.analytics,
          icon: 'icon_menu_analytics_basic_overview',
        },
        {
          title: 'menu.analyticsTemplate',
          path: CONSTANTS.paths.analyticsTemplate,
          icon: 'icon_menu_analytics_template',
        },
      ],
    },
    {
      title: 'menu.system',
      path: CONSTANTS.paths.system,
      icon: 'icon_menu_setup',
      roles: [CONSTANTS.code.adminRole],
      items: [
        {
          title: 'menu.languages',
          path: CONSTANTS.paths.languages,
          icon: 'icon_menu_languages',
          roles: [CONSTANTS.code.adminRole],
        },
        {
          title: 'menu.design',
          path: CONSTANTS.paths.design,
          icon: 'icon_add',
        },
        {
          title: 'menu.licenses',
          path: CONSTANTS.paths.licenses,
          icon: 'icon_add',
        },
        {
          title: 'menu.cookies',
          path: CONSTANTS.paths.cookies,
          icon: 'icon_menu_properties',
          wip: true,
        },
        {
          title: 'menu.notifications',
          path: CONSTANTS.paths.notifications,
          icon: 'icon_menu_properties',
        },
        {
          title: 'menu.security',
          path: CONSTANTS.paths.security,
          icon: 'icon_add',
        },
        {
          title: 'menu.about',
          path: CONSTANTS.paths.about,
          icon: 'icon_menu_setup',
        },
        {
          title: 'menu.log',
          path: CONSTANTS.paths.log,
          icon: 'icon_menu_setup',
        },
        {
          title: 'menu.debug',
          path: CONSTANTS.paths.debug,
          icon: 'icon_menu_setup',
        },
      ],
    },
    {
      hideCalendar: this.configService.value.hideCalendar,
      title: 'menu.cue-calendar.main',
      path: CONSTANTS.paths.calendar.main,
      icon: 'icon_calendar',
      roles: [CONSTANTS.code.adminRole],
      items: [
        {
          title: 'menu.cue-calendar.configuration',
          path: CONSTANTS.paths.calendar.configuration,
          icon: 'icon_add',
        },
        {
          title: 'menu.cue-calendar.users',
          path: CONSTANTS.paths.calendar.users,
          icon: 'icon_add',
        },
        {
          title: 'menu.cue-calendar.resources',
          path: CONSTANTS.paths.calendar.resources,
          icon: 'icon_add',
        },

        {
          title: 'menu.cue-calendar.userGroups',
          path: CONSTANTS.paths.calendar.userGroups,
          icon: 'icon_add',
        },
        {
          title: 'menu.cue-calendar.logs',
          path: CONSTANTS.paths.calendar.logs,
          icon: 'icon_add',
        },
      ],
    },
  ];

  isWIP: boolean;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private accountService: AccountService,
    private configService: ConfigService,
  ) {
    router.events
      .pipe(
        // Zajima me vzdycky kdyz se dokonci navigace
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      )
      .subscribe((event) => {
        // Najdu pripadne nejaky Event item, ktereho se to tyka
        const foundItem = this.menu.find(
          (mi) =>
            mi.items.find((mmi) =>
              router.isActive('/' + mmi.path, {
                paths: 'subset',
                queryParams: 'subset',
                matrixParams: 'subset',
                fragment: 'ignored',
              }),
            ) != null,
        );

        // pokud najdu udelam principialne uplne to same jako kdyz kliknu na expander..
        if (foundItem) {
          this.onMenuItemExpand(foundItem, true);
        }
      });
    this.accountService.getUserRoleStore().subscribe((roleId) => {
      this.userRoleId = roleId;
    });

    this.isWIP =
      this.configService.value.wip !== null && this.configService.value.wip !== undefined
        ? this.configService.value.wip
        : this.configService.value.apiWip;
  }

  anySubActive(item: MenuItemModel2) {
    return (
      item.items.find((mmi) =>
        this.router.isActive('/' + mmi.path, {
          paths: 'subset',
          queryParams: 'subset',
          matrixParams: 'subset',
          fragment: 'ignored',
        }),
      ) != null
    );
  }

  trackMenuItemsFn(index: number, item: MenuItemModel2) {
    return index;
  }

  onMenuItemExpand(item: MenuItemModel2, newExpand: boolean) {
    // Chci udelat to, ze vsechny ostatni itemy zavru (to se deje hadam vzdy), ale ten jeden, na
    // ktery jsem kliknul pripadne i otevru
    this.menu = this.menu.map((mi) => ({
      ...mi,
      selected: mi === item ? newExpand : mi.selected,
    }));
  }

  canOpen(item: any) {
    return item.roles ? item.roles.includes(this.userRoleId) : true;
  }
}
